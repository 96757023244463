import React, { useState } from "react";
import { Button, Form, Input, AutoComplete, Divider } from "antd";
import { useAuth0 } from "../../react-auth0-spa";
import { useAppContext } from "../../context";
import config from "../../api_config.json";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
};
/* eslint-enable no-template-curly-in-string */

const CardForm = () => {
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);
  const { isProd } = useAppContext();
  const { getTokenSilently } = useAuth0();
  const env = isProd ? "prod" : "dev";

  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".it", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  // const prefixSelector = (
  //   <Form.Item name="prefix" noStyle>
  //     <Select style={{ width: 70 }}>
  //       {/* <Option value="86">+39</Option>
  //       <Option value="87">+44</Option> */}
  //     </Select>
  //   </Form.Item>
  // );

  const onFinish = async ({ user }) => {
    console.log(JSON.stringify(user));

    const token = await getTokenSilently();

    let url = `${config.baseUrl[env]}/admin`;

    fetch(url, {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => console.log(json))
      .catch((err) => console.log(err));
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Form
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
        style={{ minWidth: "500px" }}
      >
        <Form.Item
          name={["user", "firstName"]}
          label="First Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={["user", "lastName"]}
          label="Last Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={["user", "mobile"]} label="Mobile">
          <Input />
        </Form.Item>
        <Form.Item name={["user", "phone"]} label="Phone">
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "companyName"]}
          label="Org Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={["user", "address"]} label="Address">
          <Input />
        </Form.Item>
        <Form.Item name={["user", "city"]} label="City">
          <Input />
        </Form.Item>
        <Form.Item name={["user", "website"]} label="Website">
          <AutoComplete
            options={websiteOptions}
            onChange={onWebsiteChange}
            placeholder="Website"
          >
            <Input />
          </AutoComplete>
        </Form.Item>
        <Form.Item
          name={["user", "email"]}
          label="Email"
          rules={[
            {
              type: "email",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Divider>Dati di Fatturazione</Divider>
        <Form.Item
          name={["user", "companyBillingName"]}
          label="Ragione Sociale"
        >
          <Input />
        </Form.Item>
        <Form.Item name={["user", "billingAddress"]} label="Indirizzo">
          <Input />
        </Form.Item>
        <Form.Item name={["user", "vat"]} label="Partita IVA">
          <Input />
        </Form.Item>
        <Form.Item name={["user", "pec"]} label="Pec">
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "electronicInvoicingId"]}
          label="Codice Destinatario"
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CardForm;
