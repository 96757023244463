import React from "react";

// Menu icons
import { UserOutlined } from "@ant-design/icons";

export const sidebarItems = [
  {
    title: "Cards",
    url: "/cards",
    icon: <UserOutlined />,
  },
];
