import React, { useState, useEffect, useCallback } from "react";
import { Button, Badge, Table, Typography, Input, Space, Col, Row } from "antd";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import { IdcardOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import { useLocalStorage } from "@rehooks/local-storage";
import { format } from "date-fns";
import { orderBy } from "lodash";

const { Title } = Typography;

const Cards = (props) => {
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [items, setItems] = useLocalStorage(`${env}.orgs`, []);

  const { getTokenSilently } = useAuth0();

  const fetchData = useCallback(
    async (_isProd, params = { size: 200 }) => {
      try {
        const token = await getTokenSilently();

        let url = `${config.baseUrl[env]}/admin`;

        if (Object.keys(params).length > 0) {
          url = `${url}?` + new URLSearchParams(params);
        }

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const responseData = await response.json();

        return responseData;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [getTokenSilently, env]
  );

  useEffect(() => {
    setLoading(true);

    const params = {
      size: 5000,
    };

    fetchData(params).then((response) => {
      const sortedItems = orderBy(
        response.data,
        ["updatedAt", "createdAt"],
        ["desc", "desc"]
      );
      const enrichedItems = sortedItems.map((item) => {
        const newItem = {
          ...item,
          lastUpdate: item.updatedAt ? item.updatedAt : item.createdAt,
        };
        if (item.createdAt) {
          item.createdAt = new Date(item.createdAt)
        }
        if (item.updatedAt) {
          item.updatedAt = new Date(item.updatedAt)
        }
        return newItem;
      });
      setItems(enrichedItems);
      setLoading(false);
    });
  }, [fetchData, setItems]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          // if (searchInput) {
          //   searchInput.select()
          // }
        }, 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Link",
      dataIndex: "slug",
      key: "slug",
      render: (slug) => {
        return (
          <Link
            to={{ pathname: `${config.cardsUrl[env]}/${slug}` }}
            target="_blank"
          >
            <IdcardOutlined />
          </Link>
        );
      },
    },
    {
      title: "Impresa",
      dataIndex: "companyName",
      key: "companyName",
      ...getColumnSearchProps("companyName"),
      sorter: {
        compare: (a, b) => {
          if (a.companyName < b.companyName) {
            return -1;
          }
          if (a.companyName > b.companyName) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: "Nome",
      dataIndex: "firstName",
      key: "firstName",
      ...getColumnSearchProps("firstName"),
      sorter: {
        compare: (a, b) => {
          if (a.firstName < b.firstName) {
            return -1;
          }
          if (a.firstName > b.firstName) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: "Cognome",
      dataIndex: "lastName",
      key: "lastName",
      ...getColumnSearchProps("lastName"),
      sorter: {
        compare: (a, b) => {
          if (a.lastName < b.lastName) {
            return -1;
          }
          if (a.lastName > b.lastName) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: "Ultimo Aggiornamento",
      dataIndex: "lastUpdate",
      key: "lastUpdate",
      render: (lastUpdate) => {
        try {
          const value = format(new Date(lastUpdate), "dd/MM/yyyy HH:mm");
          return value 
        } catch (err) {
          console.error(err);
          return "Data mancante o invalida";
        }
      },
      sorter: {
        compare: (a, b) => {
          if (a.lastUpdate < b.lastUpdate) {
            return -1;
          }
          if (a.lastUpdate > b.lastUpdate) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: "Azioni",
      render: (_, record) => {
        if (env !== 'prod') {
          return
        }
        const cardProjectId = {
          dev: "",
          prod: "SalPMFcaAg",
        };
        const giottoUrl = `${config.giottoUrl[env]}/start?tipo=${
          cardProjectId[env]
        }&servizio=${encodeURIComponent(record.slug)}`;
        return (
          <Link to={{ pathname: giottoUrl }} target="_blank">
            <Button size="small" type="default">
              Crea Card
            </Button>
          </Link>
        );
      },
    },
  ];

  return (
    <div style={{ margin: "16px 16px" }}>
      <div style={{ marginBottom: "16px" }}>
        {loading && <LinearProgress />}
      </div>
      {/* <Divider /> */}
      <Title level={3} style={{ marginBottom: "40px" }}>
        <Row justify="space-between">
          <Col span={5}>
            <span style={{ marginRight: "20px" }}>Lista Cards</span>
            <Badge overflowCount={5000} count={items.length}></Badge>
          </Col>
          <Col span={3}>
            <Link to="/cardForm">
              <Button type="primary">New Card</Button>
            </Link>
          </Col>
        </Row>
      </Title>
      {/* <Filters data={orgFilters} /> */}
      <Table
        dataSource={items}
        columns={columns}
        loading={loading}
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => {
            return (
              <div>
                <p>
                  <h3>Anagrafica</h3>
                  {record.firstName && (
                    <div>
                      <strong>Nome</strong>: {record.firstName}
                    </div>
                  )}
                  {record.lastName && (
                    <div>
                      <strong>Cognome</strong>: {record.lastName}
                    </div>
                  )}
                  {record.mobile && (
                    <div>
                      <strong>Cellulare</strong>: {record.mobile}
                    </div>
                  )}
                </p>
                <p style={{ marginTop: "30px" }}>
                  <h3>Dettagli Azienda:</h3>
                  {record.companyName && (
                    <div>
                      <strong>Impresa</strong>: {record.companyName}
                    </div>
                  )}
                  {record.phone && (
                    <div>
                      <strong>Telefono Fisso</strong>: {record.phone}
                    </div>
                  )}
                  {record.city && (
                    <div>
                      <strong>Città</strong>: {record.city}
                    </div>
                  )}
                  {record.address && (
                    <div>
                      <strong>Indirizzo</strong>: {record.address}
                    </div>
                  )}
                  {record.vat && (
                    <div>
                      <strong>P.IVA</strong>: {record.vat}
                    </div>
                  )}
                  {record.website && (
                    <div>
                      <strong>Sito</strong>: {record.website}
                    </div>
                  )}
                </p>
                <p style={{ marginTop: "30px" }}>
                  <h3>Dati di fatturazione:</h3>
                  {record.companyBillingName && (
                    <div>
                      <strong>Ragione Sociale</strong>:{" "}
                      {record.companyBillingName}
                    </div>
                  )}
                  {record.billingAddress && (
                    <div>
                      <strong>Indirizzo di fatturazione</strong>:{" "}
                      {record.billingAddress}
                    </div>
                  )}
                  {record.pec && (
                    <div>
                      <strong>PEC</strong>: {record.pec}
                    </div>
                  )}
                  {record.electronicInvoicingId && (
                    <div>
                      <strong>Codice Destinatario</strong>:{" "}
                      {record.electronicInvoicingId}
                    </div>
                  )}
                </p>
                <p style={{ marginTop: "30px" }}>
                  <h3>Altro:</h3>
                  {record.slug && (
                    <div>
                      <strong>Slug</strong>: {record.slug}
                    </div>
                  )}
                  {record.createdAt && (
                    <div>
                      <strong>Data di creazione</strong>: {record.createdAt}
                    </div>
                  )}
                  {record.updatedAt && (
                    <div>
                      <strong>Data di aggiornamento</strong>: {record.updatedAt}
                    </div>
                  )}
                  {record.id && (
                    <div>
                      <strong>ID</strong>: {record.id}
                    </div>
                  )}
                </p>
              </div>
            );
          },
        }}
      />
    </div>
  );
};

export default Cards;
